<template>
  <v-card elevation="0" class="overflow-y-auto" color="#1a1b1d" style="margin-left: 5px" min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`" :style="$vuetify.breakpoint.smAndDown
      ? `border-left: `
      : `border-left:1px solid gray`
      ">
    <v-card-text>
      <div>
        <div class="d-flex align-end mt-4 headText">
          <h2 class="headTextProject" :style="fontSize">My recipes</h2>
          <v-spacer></v-spacer>
          <v-btn small text @click="$router.go(-1)" class="orange--text" style="border: 2px solid orange">Back</v-btn>
        </div>
      </div>

      <!-- <div class="font-weight-light black--text caption mb-2">
        <p class="desc">(responsive design)</p>
      </div> -->

      <v-row>
        <v-col class="main-text mt-8">
          <br />
          <span class="textInsideProject"> Language: </span>
          Java<br />
          <span class="textInsideProject">GUI widget:</span> Swing
          <br />
          <span class="textInsideProject">Database:</span> SQL (SQLite as RDBMS)
          <br />
          <span class="textInsideProject">Short info:</span>
          This program offers the advantage of categorizing recipes by mealtime,
          allowing you to specify which meals they are suitable for. For
          instance, you can indicate that a soup recipe is meant for lunch only
          or that a steak recipe can be served for both lunch and dinner. With
          this information, you can easily sort recipes by mealtime, such as
          filtering for 'dinner' to view a list of dishes suitable for that
          time. Furthermore, the program allows you to customize your meal
          preferences by selecting lean or fatty options.

          <div class="d-flex justify-center git-gallery-live">
            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange" @click="openGallery(0)">
              <i class="orange--text fas fa-bullseye pa-0"> Gallery</i>
            </v-btn>
          </div>

        </v-col>
      </v-row>
    </v-card-text>

    <!-- Gallery -->
    <!-- <v-row class="ma-0">
      <v-col class="px-4">
        <h2 class="">Gallery</h2>
        <v-container class="mt-5" grid-list-md>
          <v-layout row wrap>
            <v-flex
              xs6
              sm4
              lg2
              v-for="(image, i) in images"
              :key="i"
              class="d-flex justify-center"
            >
              <img
                v-lazy="image.src"
                :style="picSize"
                style="cursor: pointer"
                @click="openGallery(i)"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
    </v-row> -->

    <!-- Import component LightBox za prikaz galerije sa kontrolama levo i desno-->
    <LightBox ref="lightbox" :media="images" :show-caption="true" :show-light-box="false" :closable="true" />
  </v-card>
</template>

<script>
  import LightBox from "vue-image-lightbox";
  require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

  export default {
    components: { LightBox },
    data() {
      return {
        images: [
          {
            thumb: require("@/assets/projects/MyRecipes/recepti-glavni-meni.png"),
            src: require("@/assets/projects/MyRecipes/recepti-glavni-meni.png"),
            caption: "<h4 class='pt-2'>Main menu</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-2.png"),
            src: require("@/assets/projects/MyRecipes/slika-2.png"),
            caption: "<h4 class='pt-2'>'about'</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-3.png"),
            src: require("@/assets/projects/MyRecipes/slika-3.png"),
            caption: "<h4 class='pt-2'>Main look</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-4.png"),
            src: require("@/assets/projects/MyRecipes/slika-4.png"),
            caption: "<h4 class='pt-2'>Sorting by meal</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-5.png"),
            src: require("@/assets/projects/MyRecipes/slika-5.png"),
            caption: "<h4 class='pt-2'>Sorting by type of dish</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-6.png"),
            src: require("@/assets/projects/MyRecipes/slika-6.png"),
            caption: "<h4 class='pt-2'>Lean, greasy and indeterminate</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-7.png"),
            src: require("@/assets/projects/MyRecipes/slika-7.png"),
            caption: "<h4 class='pt-2'>Display of one recipe</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-8.png"),
            src: require("@/assets/projects/MyRecipes/slika-8.png"),
            caption: "<h4 class='pt-2'>Display of another recipe</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/recepti-unos-novog.png"),
            src: require("@/assets/projects/MyRecipes/recepti-unos-novog.png"),
            caption: "<h4 class='pt-2'>New recipe</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-9.png"),
            src: require("@/assets/projects/MyRecipes/slika-9.png"),
            caption: "<h4 class='pt-2'>Edit recipes</h4>",
          },
          {
            thumb: require("@/assets/projects/MyRecipes/slika-10.png"),
            src: require("@/assets/projects/MyRecipes/slika-10.png"),
            caption: "<h4 class='pt-2'>Delete recipe</h4>",
          },
        ],
      };
    },
    computed: {
      fontSize() {
        switch (this.$vuetify.breakpoint.name) {
          // case "xs":
          // case "sm":
          //   return { "font-size": "21px !important" };
          default:
            return {
              "font-size": "1.3em",
              "letter-spacing": "0.05em",
              "text-transform": "uppercase",
            };
        }
      },
      picSize() {
        switch (this.$vuetify.breakpoint.name) {
          case "lg":
            return { width: "95%", height: "80px" };
          default:
            //tj. xs, sm, md
            return { width: "auto", height: "96px", overflow: "hidden" };
        }
      },
    },
    methods: {
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
      },
    },
  };
</script>

<style></style>
